@import './fonts.scss';

html,
body {
  padding: 0;
  margin: 0;
}

html {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &.dark {
    // color-scheme: dark;

    ::-webkit-scrollbar-track {
      background: #070f16; // secondary 800

      &:hover {
        background: #03080b; // secondary 900
      }
    }

    ::-webkit-scrollbar-thumb {
      background: #0c414f; // primary 800

      &:hover {
        background: #78c8dc; // primary 300
      }
    }
  }

  &.light {
    color-scheme: light;

    ::-webkit-scrollbar-track {
      background: #eeeeee; // grey 50

      &:hover {
        background: #dddddd; // grey 100
      }
    }

    ::-webkit-scrollbar-thumb {
      background: #a0a8af; // secondary 200

      &:hover {
        background: #0a1720; // secondary 700
      }
    }
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 100;
}

#root {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
}

:global {
  .grecaptcha-badge {
    visibility: hidden;
  }

  .hidden {
    display: none !important;
    visibility: hidden !important;
  }
}
