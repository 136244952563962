.table {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  &.dragging {
    .row {
      .row-bg {
        background-color: transparent !important;
      }
    }
  }

  transition: opacity;
  transition-duration: 0.35s;

  &.busy {
    opacity: 0.2;
    pointer-events: none;
  }
}

.header {
  display: flex;
  width: 100%;
  padding: 10px 10px;

  .cell {
    font-weight: 900;
    font-size: 14px;

    &-content-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        .sort-btn {
          opacity: 0.5;
        }
      }

      &.clickable {
        * {
          cursor: pointer;
          pointer-events: visiblePainted;
        }
      }
    }
  }

  .sort-btn {
    display: block;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    opacity: 0;
    transition: opacity, visibility;
    transition-duration: 0.35s;

    &.active {
      opacity: 1 !important;
    }
  }
}

.content {
  position: inherit;
}

.cell {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  z-index: 2;
  flex: 1;
  padding-right: 10px;
}

.actions-cell {
  flex: 0;

  .actions {
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility;
    transition-duration: 0.35s;

    .icon-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 16px;
      position: relative;
      margin-left: 10px;
      opacity: 0.5;
      transition: opacity;
      transition-duration: 0.35s;

      svg {
        max-width: 22px;
        max-height: 22px;
      }

      &:not(:first-of-type) {
        &:before {
          pointer-events: none;
          content: '';
          display: block;
          width: 1px;
          height: 20px;
          background-color: rgba($color: #fff, $alpha: 0.3);
          position: absolute;
          left: -5px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.row {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 20px 10px;
  margin-bottom: 0;

  &-bg {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .row-bg {
      opacity: 1;
      visibility: visible;
    }

    .actions {
      opacity: 1;
      visibility: visible;
    }
  }

  &.selected {
    .row-bg {
      opacity: 0.5;
      visibility: visible;
    }

    .actions {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      .row-bg {
        opacity: 0.8;
        visibility: visible;
      }
    }
  }
}

.footer {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.no-data-label {
  padding: 10px 10px;
  width: 100%;
  text-align: center;
}

.delete-confirmation {
  display: flex;
  align-items: center;

  & > div {
    margin-right: 5px;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: calc(50% - 30px);
  transform: translate(-50%, -50%);
  z-index: 10;
}
